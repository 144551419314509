<template>
  <div>
    <el-form :model="myForm" ref="editNumAndGaiLvRef" :rules="rules" label-width="100px" size="small">
      <el-form-item label="奖品总数" prop="num">
        <el-input v-model="myForm.num" clearable placeholder="请输入奖品总数"></el-input>
      </el-form-item>

      <el-form-item label="概率" prop="winRate">
        <el-input v-model="myForm.winRate" clearable placeholder="请输入概率"></el-input>
        %
      </el-form-item>

      <el-form-item>
        <div class="btnDiv">
          <el-button @click="quXiaoClick">取 消</el-button>
          <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
import { makeForm } from '@/utils/tools.js'
export default {
  props: ['rowData'],
  components: {},
  data() {
    return {
      myForm: {
        num: '', // 奖品总数
        winRate: '', // 概率
        id: '',
        tenantName: '',
      },
      rules: {
        num: [{ required: true, message: '请输入奖品总数', trigger: 'blur' }],
        winRate: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              console.log('rule', rule, value)
              if (!value) {
                return callback(new Error('请输入中奖概率'))
              }
              if (isNaN(value)) {
                return callback(new Error('请输入数字值'))
              } else {
                if (value < 0 || value > 100) {
                  return callback(new Error('请输入0到100的数字'))
                } else {
                  return callback()
                }
              }
            },
          },
        ],
      },
    }
  },
  created() {},
  mounted() {
    if (this.rowData) {
      this.rowData.tenantName = JSON.parse(localStorage.getItem('jiangChiRow')).tenantName
      console.log('this.rowData', this.rowData)
      makeForm(this.myForm, this.rowData)
    }
  },
  methods: {
    // 确定
    async diaQueDingClick() {
      this.$refs.editNumAndGaiLvRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$API.jiangPin.setJiangPinNumAndGaiLv(this.myForm)
          if (res.status != 200) return this.$message.error(res.msg)
          this.$emit('diaQueDingClick_jpsz')
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('diaQuXiaoClick_jpsz')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
