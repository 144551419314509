<template>
  <div>
    <el-form :model="myForm" ref="editJiangPinRef" :rules="rules" label-width="100px" size="small">
      <el-form-item label="奖品类别" prop="category">
        <el-select v-model="myForm.category" placeholder="请选择类别">
          <el-option :value="1" label="实物奖品"></el-option>
          <el-option :value="2" label="卡券类"></el-option>
          <el-option :value="3" label="感谢参与"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="奖品名称" prop="name">
        <el-input v-model="myForm.name" placeholder="请输入奖品名称"></el-input>
      </el-form-item>
      <el-form-item label="奖品图片" prop="thumbnail">
        <uploadImg @imgChage="imgChage" name="thumbnail" :imgUrl="myForm.thumbnail"></uploadImg>
      </el-form-item>
      <el-form-item label="奖品描述">
        <el-input :autosize="{ minRows: 3, maxRows: 6 }" type="textarea" v-model="myForm.description"></el-input>
      </el-form-item>

      <el-form-item>
        <div class="btnDiv">
          <el-button @click="quXiaoClick">取 消</el-button>
          <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { makeForm } from '@/utils/tools.js'
export default {
  props: ['rowData'],
  components: { uploadImg },
  data() {
    return {
      myForm: {
        name: '', // 奖品名称
        thumbnail: '', // 奖品图片
        description: '', // 奖品描述
        id: '',
        poolId: '',
        category: '',
      },
      rules: {
        category: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        name: [{ required: true, message: '请输入奖品名称', trigger: 'blur' }],
        thumbnail: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      jiangChiRow: '',
    }
  },
  created() {},
  mounted() {
    this.jiangChiRow = JSON.parse(localStorage.getItem('jiangChiRow'))
    if (this.rowData) {
      makeForm(this.myForm, this.rowData)
    }
    console.log('this.myForm', this.myForm)
  },
  methods: {
    imgChage(item) {
      this.myForm[item.name] = item.url
    },
    // 确定
    async diaQueDingClick() {
      this.$refs.editJiangPinRef.validate(async (valid) => {
        if (valid) {
          let canshu = {
            ...this.myForm,
            poolId: this.jiangChiRow.id,
            tenantName: this.jiangChiRow.tenantName,
          }
          // 编辑
          if (this.rowData) {
            const res = await this.$API.jiangPin.update(canshu)
            if (res.status != 200) return this.$message.error(res.msg)
          } else {
            const res = await this.$API.jiangPin.add(canshu)
            if (res.status != 200) return this.$message.error(res.msg)
          }
          this.$emit('diaQueDingClick')
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('quXiaoClick')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
