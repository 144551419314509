<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addClick()">添加</el-button>
      </div>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="奖品名称" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="tenantName" label="奖品图片" min-width="150">
          <template slot-scope="scope">
            <div>
              <img style="width: 50px; height: 50px" :src="scope.row.thumbnail" alt="" srcset="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="category" label="奖品类别" min-width="150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.category | prizeCategory }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="奖品总数" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="usedNum" label="已用奖品数量" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="winRate" label="获奖概率" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" min-width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="sheZhiClick(scope.row)">设置</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
            <el-button type="primary" v-if="scope.row.category == 2" size="mini" @click="showKaJun(scope.row)">卡券列表</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  编辑奖品/ 添加奖品-->
    <el-dialog :title="rowData ? '编辑奖品' : '添加奖品'" :visible.sync="addJiangPinDia" v-if="addJiangPinDia" width="50%" @close="addJiangPinDia = false">
      <addJiangPinDia :rowData="rowData" @quXiaoClick="addJiangPinDia = false" @diaQueDingClick="diaQueDingClick"></addJiangPinDia>
    </el-dialog>

    <!-- 设置奖品数量和概率 -->
    <el-dialog title="设置奖品数量和概率" :visible.sync="numAndGaiLvDia" v-if="numAndGaiLvDia" width="50%" @close="numAndGaiLvDia = false">
      <numAndGaiLv :rowData="rowData" @diaQuXiaoClick_jpsz="numAndGaiLvDia = false" @diaQueDingClick_jpsz="diaQueDingClick_jpsz"></numAndGaiLv>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import addJiangPinDia from '@/components/addJiangPinDia.vue'
import numAndGaiLv from '@/components/numAndGaiLv.vue'

export default {
  components: { pagination, addJiangPinDia, numAndGaiLv },
  mixins: [commonMixin],
  data() {
    return {
      addJiangPinDia: false, // 新增奖品
      numAndGaiLvDia: false, // 设置奖品数量和概率
      params: {
        pageNum: 1,
        pageSize: 10,
        poolId: '',
      },
      records: [],
      total: 0,
      rowData: '',
      jiangChiRow: '',
    }
  },

  mounted() {
    this.jiangChiRow = JSON.parse(localStorage.getItem('jiangChiRow'))
    this.params.poolId = this.jiangChiRow.id
    this.params.tenantName = this.jiangChiRow.tenantName
    this.getData()
  },
  methods: {
    // 获取列表
    async getData() {
      const res = await this.$API.jiangPin.getJiangPinList(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      const { records, total } = res.data
      this.records = records
      this.total = parseInt(total)
    },

    // 添加
    addClick() {
      this.rowData = ''
      this.addJiangPinDia = true
    },
    // 编辑
    updateClick(row) {
      this.rowData = row
      this.addJiangPinDia = true
    },
    // 弹框上 确定 click
    async diaQueDingClick() {
      this.addJiangPinDia = false
      this.getData()
    },

    // 设置Click
    sheZhiClick(row) {
      this.rowData = row
      this.numAndGaiLvDia = true
    },

    //  删除
    deleteItem(row) {
      const params = {
        id: row.id,
        tenantName: this.jiangChiRow.tenantName,
      }
      this.$confirm('此操作将永久删除该奖品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.jiangPin.delete(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
            // resolve(true)
          }
        })
        .catch((err) => err)
    },
    // 奖品设置 dia  确定click
    diaQueDingClick_jpsz() {
      this.numAndGaiLvDia = false
      this.getData()
    },
    showKaJun(item) {
      this.$router.push({ path: '/poolGuanLi/kaJunList?id=' + item.id + '&tenantName=' + item.tenantName + '&name=' + item.name })
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
